<template>
  <div style="display: flex; justify-content: stretch">
    <div style="margin-left: 20px; margin-right: 20px; flex: 1">
      <v-container>
        <Board
          :savingJob.sync="savingJob"
          :gettingJson="false"
          :showJson="false"
          v-on:saveBoard="saveJob"
        >
          <template>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="10">
                  <v-text-field
                    @change="busyEditting = true"
                    label="Naam"
                    :rules="[rules.required]"
                    v-model="board.columns[1].name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-checkbox
                    v-model="board.columns[1].isActive"
                    label="In Gebruik"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    disabled
                    auto-select-first
                    label="Type"
                    :rules="[rules.required]"
                    :items="board.routingTypes"
                    item-text="name"
                    item-value="id"
                    v-model="board.columns[1].routingTypeId"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
          </template>
        </Board>
      </v-container>
      <!-- snackbar on save -->
      <v-snackbar
        v-model="saveSnackbar"
        :multi-line="true"
        :color="snackbarSuccess ? 'light-green' : 'red'"
      >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="saveSnackbar = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Board from "@/components/Board";
import services from "@/services/services.js";

export default {
  components: {
    Board,
  },
  created() {
    this.updateRoutingTypes();
  },
  computed: {
    ...mapState(["board"]),
  },
  data() {
    return {
      savingJob: false,
      saveSnackbar: false,
      snackbarSuccess: true,
      snackbarText: "",
      rules: {
        required: (value) => !!value || "Verplicht.",
      },
    };
  },
  methods: {
    saveJob(column) {
      this.savingJob = true;
      if (this.resourcesFilledIn(column)) {
        // preparing job for saving
        this.prepareFinalTasks(column);
        let templateRouting = {};
        templateRouting.id = column.id;
        templateRouting.routingTemplateId = column.routingTemplateId;
        templateRouting.isActive = column.isActive;
        templateRouting.name = column.name;
        templateRouting.routingTypeId = column.routingTypeId;
        templateRouting.routingStepTemplates = column.tasks;

        // sending job to api
        services
          .saveRoutingTemplate(templateRouting)
          .then((response) => {
            // console.log(response);
            if (response.data) {
              this.snackbarSuccess = true;
              this.snackbarText = "Opslaan was succesvol.";
              this.saveSnackbar = true;
            }
          })
          .catch((error) => {
            this.snackbarSuccess = false;
            this.snackbarText =
              "Er was een probleem, gelieve opnieuw te proberen.";
            this.saveSnackbar = true;
            console.log(error.response.data.title);
          })
          .finally(() => {
            this.savingJob = false;
          });
      } else {
        this.snackbarSuccess = false;
        this.snackbarText =
          "Gelieve de foutmeldingen eerst op te lossen alvorens op te slaan.";
        this.saveSnackbar = true;
        this.savingJob = false;
      }
    },
    prepareFinalTasks(column) {
      for (let index = 0; index < column.tasks.length; index++) {
        column.tasks[index].sortKey = index;
      }
    },
    resourcesFilledIn(column) {
      for (let index = 0; index < column.tasks.length; index++) {
        if (
          column.tasks[index].resourceId ==
          "00000000-0000-0000-0000-000000000000"
        ) {
          return false;
        }
      }
      return true;
    },

    updateRoutingTypes() {
      services
        .getRoutingTypes(true)
        .then((response) => {
          this.$store.commit("SET_ROUTINGTYPES", {
            response,
          });
        })
        .catch((error) => {
          this.$store.commit("SAVE_ERROR", { error });
          console.log(error.response.data.title);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="css">
.task-bg {
  background: rgba(0, 0, 0, 0.5);
}
</style>
